<template>
	<div class="about gradient-background">
		<div class="versions">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
		<h1>Documentation</h1>
		<div class="container">
			<div class="docs">
				<h2>Cashier Workflow</h2>
				<h3 class="heading">Open Bank</h3>
				<ol>
					<li>
						Log into the cashier account. If this is the first time using the app, you must enable the serial port to use the printer (if
						installed on this computer or device). Then the enable serial port dialog will show up. Enable "EPSON COM Emulation USB Port"
					</li>
					<li>Click Open Cashier Bank.</li>
					<li>Enter the cash amoumt that you are adding to the cashier bank.</li>
					<li>Click Open Bank</li>
					<ul>
						<li>
							If a bank is already opened at this casino, Cashier will be taken to it on the Bank List View and a highlight will flash
							on the bank id.
						</li>
						<li>Cashier can click View on that bank to do transactions.</li>
						<li>
							Cashier can close that bank if Cashier wants to open a new bank. Normally banks should always be closed at the end of a
							shift.
						</li>
					</ul>
					<li>
						After opening a bank or clicking View from Bank List, Cashier will be taken to the Bank View. There are various bank related
						this that can be done from this view without having a plyer session open.
					</li>
				</ol>

				<h3 class="heading">Begin Player Session</h3>
				<p>
					There are three ways to begin a player session. QR code scanner (if enabled on the server), verbal code manual entry (if enabled
					on the server), and SMS text code. If the player is using the QR scanner for the first time, they must give the app permission to
					use the camera.
				</p>
				<ol>
					<li>Use one of the methods listed above to enable player transactions.</li>
					<li>Cash in is done through Buy-in offers. Click the desired buy-in offer.</li>
					<li>Click into the input.</li>
					<li>A keypad will appear and you can enter the amount of cash the player is handing you.</li>
					<li>Click enter on the keypad and then click the Commit Transaction button on the buy in.</li>
				</ol>
				<ul>
					<li>Click Cash Out when a player wants to cash out.</li>
					<li>List Locked Funds is a view where fund locks can be unlocked.</li>
				</ul>

				<h3 class="heading">List Cashier Bank</h3>
				<ul>
					<li>If Cashier has an open bank, click View to start working with that bank</li>
					<li>
						A Site Admin can see all open banks at the casino they're logged into and has the permissions to close any Cashier Bank even
						if it's not theirs
					</li>
					<li>A cashier can only see the bank that belongs to them</li>
					<li>To see a history of closed banks, uncheck Opened Banks Only (checked by default)</li>
					<li>Click on any bank row in the table to see a cashier bank summary.</li>
				</ul>

				<h3 class="heading">Bank View</h3>
				<p>
					Receipt printer requires serial port access and a
					<a class="psuedo-anchor" href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts" target="_blank"
						>secure context</a
					>
					(https://). Serial ports are currently only suppored by Chromium based browsers.
				</p>
				<p>
					There are three ways to begin a player session. QR code scanner (if enabled on the server), verbal code manual entry (if enabled
					on the server), and SMS text code. If the player is using the QR scanner for the first time, they must give the app permission to
					use the camera.
				</p>

				<ol>
					<li>In the Bank View, Cashier can Fill Cashier Bank, view past Bank Transfers, or view past Bank Fills</li>
					<li>Bank View starts in Begin Player Session mode</li>
					<li>
						<em style="font-weight:bold">Chrome and Edge (Chromium Browsers):</em> If this is the first time this browser has visited Bank
						View and your browser is in a
						<a class="psuedo-anchor" href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts" target="_blank"
							>secure context</a
						>
						(https://), you will be prompted to allow serial port access.
					</li>
					<li>Use one of the methods listed above to enable player transactions.</li>
					<li>
						After that step, Bank View enters Buy-in Offers mode. In this mode Cashier can transact with Player accounts and List Locked
						Funds.
					</li>
					<li>
						<em style="font-weight:bold">Chrome and Edge (Chromium Browsers):</em> After transferring funds, transaction data is sent to
						printer for receipt. Your browser must have serial port permissions and be connected to a
						<a class="psuedo-anchor" href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts" target="_blank"
							>secure context</a
						>
						(https://).
					</li>

					<li>Click End Player Session to start transacting with a new Player</li>
					<li>If Cashier clicks Close this bank, it will also End Player Session</li>
				</ol>
				<h3 class="heading">Funds Lock Manager</h3>
				<ul>
					<li>Lists any locked funds</li>
					<li>Click Unlock to unlock that locked fund</li>
					<li>To see a history of locked funds, uncheck Unclaimed Locks Only (checked by default)</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "About",
	props: {
		serverVersion: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	overflow: hidden;
}

h1 {
	text-align: center;
}

.about {
	position: relative;
	margin-left: 20%;
	margin-bottom: 30px;
	padding: 15px;
	overflow: hidden auto;
	height: calc(100vh - 190px);
}

.container {
	display: flex;
	flex-flow: column;
	width: 95%;
	margin: 15px auto;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 8px;
}

.psuedo-anchor {
	color: #847eff;
}

h3 {
	text-align: left;
}

h3.heading {
	background-color: #32373f;
	padding: 15px;
}

h3.heading {
	margin-top: 3em;
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	margin: 5px 15px;
	text-align: center;
}
</style>
